export enum environments {
  nonprod = "nonprod",
  prod = "prod"
}

const nonprodEnvs = ["localhost", "np.", "np-", "dev", "dev2", "qa"];

export const deriveEnvironmentName = () => {
  const url = window.location.href;
  return nonprodEnvs.some(x => url.includes(x))
    ? environments.nonprod
    : environments.prod;
};
